import { environment } from "../environments/environment";

export const APPMeta = {
    VERSION: "1.9.4",
    BUILD: "19401",
    ENV: environment.name,
};

export interface AppError {
    exception: string;
}
export enum AppExceptions {
    InvalidPlaceCode = "InvalidPlaceCode",
    UserAlreadyJoinedPlace = "UserAlreadyJoinedPlace",
    InvalidLicenseCode = "InvalidLicenseCode",
    UserWrongLogin = "UserWrongLogin",
    UserNotEnoughPoints = "UserNotEnoughPoints",
    UserAlreadyReadPost = "UserAlreadyReadPost",
    UserAlreadyJoinedEvent = "UserAlreadyJoinedEvent",
    MissingUploadFile = "MissingUploadFile",
    DeleteAdmin = "DeleteAdmin",
    InvalidPrizeCode = "InvalidPrizeCode",
    UserCannotDelete = "UserCannotDelete",
}

enum UserGender {
    Male = "male",
    Female = "female",
}

export enum PlaceType {
    Basic = "basic",
    Premium = "premium",
}
export const enum StreamView {
    All = "all",
    Drafts = "drafts",
}

/** Models  */
export interface User {
    id?: number;
    gender: string;
    birthyear: number;
    username: string; // unique
    password: string;
    api_token: string;
    platform: string;
    language: string;
    device_token: string; // unique?
    app_version: string;
    terms_accepted_at: string;
    points: number;
    exp: number;
    level: number;
    exp_level: number;
    exp_next_level: number;
    achievement: Achievement;
    ideas: Idea[];
    events: Event[];
}
export interface Achievement {
    id: number;
    key: string;
    name: string;
    description: string;
    points: number;
    is_progressable: boolean;
    locked: boolean;
    points_completed: number;
    unlocked_at: number;
    created_at: number;
    updated_at: number;
}

export interface PlaceLeaveable {
    id: number;
    title: string;
    leaveable: boolean;
    message: string;
}
export interface PaginationList<T> {
    data: T[];
    meta: PaginationMeta;
}
export interface PaginationMeta {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
}

export interface Place {
    id: number;
    type: PlaceType;
    active: number;
    title: string;
    short_description: string;
    long_description: string;
    welcome_message: string;
    prize_description: string;
    address: string;
    state: string;
    city: string;
    zip: string;
    country: string;
    created_at: string;
    updated_at: string;
    image: string;
    admin: boolean; // isAdmin
    all_points: number;
    claimed_points: number;

    // State Management
    posts: Post[];
    events: Event[];
    elections: Election[];
    prizes: Prize[];
    reports: PlaceReport[];

    // Place Admin
    licenses: License[];
    admins: UserStream[];
    highscore: HighscoreUser[];
    users: PaginationList<User>;
    stats: PlaceStats;
    code: string;
    qrcodeURL: string;
    ideas: Idea[];
    publicIdeas: Idea[];
    myIdeas: Idea[];
    dashboard: PlaceDashboard;
}

export interface PlaceDashboard {
    qrcode: string;
    postCount: number;
}

export interface PlaceStats {
    users: number;
    ideas: number;
    earned_exp: number;
    earned_points: number;
    redeemed_points: number;
}

export interface Post {
    id?: number;
    expired?: boolean;
    expire_at?: number;
    expire_in?: string;
    publish_result?: boolean;
    place_id?: number;
    status?: string;
    url?: string;
    export?: string;
    printUrl?: string;
    title: string;
    short_description?: string;
    description?: string;
    place?: Place;
    created_at?: number;
    publish_at?: number;
    questions: Question[];
    events: Event[];
    elections: any[];
    featured?: boolean;
    notification?: boolean;
    author?: UserStream;
    image?: string;
    youtube?: string;
    youtube_id: string;
    points?: number;
    read?: boolean;
    liked?: boolean;
    likes?: number;
    hideQuestions?: boolean;
    all_points: number;
    claimed_points: number;

    // Admin States
    readCount?: number;
    code?: string;
    qrcodeURL?: string;
}

export interface Election {
    id: number;
    uuid: string;
    name: string;
    url: string;
    edit_url: string;
    start_date: number;
    end_date: number;
    end_date_format: string;
    start_date_format: string;
}

export interface Event {
    id: number;
    place: Place;
    status: string;
    ical: string;
    googleCalendar: string;
    maps: string;
    place_id: number;
    title: string;
    description: string;
    formattedAddress: string;
    address: string;
    state: string;
    city: string;
    zip: string;
    country: string;
    start_at: number;
    start_at_formatted: string;
    end_at: number;
    end_at_formatted: string;
    image?: any;
    full_image?: any;
    longitude?: number;
    latitude?: number;
    qrCode?: string;
    isPast?: boolean;
    joined?: boolean;
}

export interface UserStream {
    id: number;
    username: string;
}
export interface Question {
    id?: number;
    optional?: boolean;
    others?: boolean;
    title: string;
    description: string;
    exp?: number;
    points: number;
    type: string;
    answers: Answer[];
    user_answers: any[];
    shuffled_answers: Answer[]; // Vom Client shuffled Answer Array (wichtig für quiz)
}

export interface Answer {
    id?: number;
    title: string;

    // Admin - Anzahl der Benutzer die diesen Antwort gegeben haben
    count?: number;
    user_answers: string[]; // Antworten von Benutzern
}

export interface Prize {
    id: number;
    status: string;
    place_id: number;
    title: string;
    description: string;
    cost: number;
    image: string;
    qrCode?: string;
}
export interface Sponsor {
    id: number;
    title: string;
    licenses?: any[];
}
export interface License {
    id: number;
    code: string;
    place?: Place;
}
export interface Upload {
    id: number;
    url: string;
}

export interface Idea {
    id: number;
    status: string;
    can: {
        comment: boolean;
        updateStatus: boolean;
    };
    place_id: number;
    place_title: string;
    title: string;
    description: string;
    note: string;
    image?: any;
    read_at: number; // unix timestamp
    favorite: boolean;
    latitude: number;
    longitude: number;
    comments: any[];
    votes: number;
    maps: string;
}

export interface HighscoreUser {
    rank: number;
    username: string;
    achievement: string;
    level: number;
}

export interface Transaction {
    exp: number;
    points: number;
    place_id: number;
}

export interface PushToken {
    user_id: number;
    type: string;
    token: string;
}
export interface AppVersion {
    version: string;
    build: string;
}

/**  Requests */

export interface QuestionAnswerRequest {
    questions: {
        id: number;
        answers: {
            id: number;
            text?: string;
            image?: string;
        }[];
    }[];
}

export interface LoginRequest {
    username: string;
    password: string;
}

export interface UserUpdateRequest {
    gender?: UserGender;
    birthyear?: number;
    username?: string;
    password?: string;
}

export interface PrizeRequest {
    id: number;
    title: string;
    description: string;
    cost: number;
    status: string;
    upload_id?: number;
}

export interface PostRequest {
    place_id: number;
    title: string;
    short_description: string;
    description: string;
    publish_at: number; // unix timestamp
    featured: boolean;
    notification: boolean;
    youtube?: string;
    questions: Question[];
    points: number;
}

export interface IdeaCreateRquest {
    place_id: number;
    title: string;
    description: string;
}

export interface Reportable {
    reportable_id: number;
    reportable_type: string;
    comment?: string;
}

export interface ElectionCreateRequest {
    place_id: number;
    name?: string;
}

export interface PlaceReport {
    id: number;
    comment: string;
    user: User;
    post: Post;
}
export interface PlaceReportDeleteRequest {
    report_id: number;
    place_id: number;
}

export interface PlaceEventGetRequest {
    place_id: number;
    event_id: number;
}
export interface PlaceEventJoinRequest {
    place_id: number;
    event_id: number;
    code: string;
}

export interface PlaceUsersGetRequest {
    place_id: number;
    page: number;
}
export interface PlaceElectionsGetRequest {
    place_id: number;
}
export interface PlaceUserRemoveRequest {
    place_id: number;
    user_id: number;
}
export interface IdeaUpdateRequest {
    place_id: number;
    idea_id: number;
    status: string;
}
export interface IdeaCommentStoreRequest {
    idea_id: number;
    comment: string;
}
export interface IdeaVoteRequest {
    place_id: number;
    idea_id: number;
    vote: string;
}
